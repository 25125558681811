import React from "react";
import { Link } from "react-router-dom";
import config from "../../config/app.js";

function PaymentMode() {
  return (
    <Link to="/payment-methods">
    <div className="payment-mode">
      <div className="foot-heading text-center-mobile">
        <h4>All payment modes accepted :</h4>
      </div>
      <ul className="modes mx-auto-mobile">
        {
          /**
           * BOC: Commented the code to remove the PayPal logo from the footer
           * Date: 12-02-2025
           * Author: Anurag Chauhan
           * Task Id: 158170
           */
        }
        {/* <li>
          <Link to="/payment-methods">
            <img
              src={config.skinURL + "/assets/images/paypal_logo.png"}
              alt=""
            />
          </Link>
        </li> */}
        <li>
          <Link to="/payment-methods">
            <img
              src={config.skinURL + "/assets/images/visa_logo.png"}
              alt=""
            />
          </Link>
        </li>
        <li>
          <Link to="/payment-methods">
            <img
              src={
                config.skinURL + "/assets/images/master_card_logo.png"
              }
              alt=""
            />
          </Link>
        </li>
        <li>
          <Link to="/payment-methods">
            <img
              src={config.skinURL + "/assets/images/klarna_logo.png"}
              alt=""
            />
          </Link>
        </li>
        <li>
          <Link to="/payment-methods">
            <img
              src={config.skinURL + "/assets/images/alipay_logo.png"}
              alt=""
            />
          </Link>
        </li>
        <li>
          <Link to="/payment-methods">
            <img
              src={
                config.skinURL + "/assets/images/afterPay_logo.png"
              }
              alt=""
            />
          </Link>
        </li>
        <li>
          <Link to="/payment-methods">
            <img
              src={
                config.skinURL + "/assets/images/american_express_logo.png"
              }
              alt=""
            />
          </Link>
        </li>
      </ul>
    </div>
    </Link>
  );
}

export default PaymentMode;
