import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useDispatch } from "react-redux";
import {
  appSendLoginOtp,
  appVerifyOTPAndLogin,
} from "../../services/AuthServices";
import { LOGIN_SUCCESS } from "../../store/actions/types";
import { PLEASE_WAIT_TXT } from "../../utils/Constant";

function OTPLogin({
  active,
  toggleAuthFormCb,
  otpResponse,
  userName,
  setVerifyOTPResponse,
}) {
  const [counter, setCounter] = useState(60);
  const [otpToVerify, setOtpToVerify] = useState("");
  const [msg, setMsg] = useState("");
  const [responseData, setResponseData] = useState(otpResponse);
  const [isSubmitting, SetIsSubmitting] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    const timer =
      counter > 0 && setInterval(() => setCounter(counter - 1), 1000);
    return () => clearInterval(timer);
  }, [counter]);

  if (!active && !otpResponse && otpResponse?.status === "success" && !userName)
    return null;

  const onVerifyOTPLogin = (e) => {
    e.preventDefault();
    if (otpToVerify) {
      SetIsSubmitting(true);
      appVerifyOTPAndLogin({ login_id: userName, otp: otpToVerify }).then(
        (res) => {
          res = JSON.parse(res.data);
          if (res?.login_user?.status === "success") {
            /**
             * BOC
             * Task#158182-Extremely-Urgent-The-customer-logins-via-otp-they-cannot-use-the-website
             * author - Mohammad Meraj
             * date 07-Feb-2025
             * Use_of_code:- The following code is used to remove the inline style from the <body> tag when the API response is successful.
             *  If we do not remove the inline style from the <body> tag, users will be unable to scroll the page due to the inline style. 
             * The inline style added to the <body> tag is: style="position: fixed; top: -0px; left: 0; right: 0;".
             */
            document.body.setAttribute('style',''); 
            /**
             * EOC
             */
            let loginUser = res?.login_user;
            loginUser = { ...loginUser, token: res?.SID };
            dispatch({
              type: LOGIN_SUCCESS,
              payload: loginUser,
            });
            setVerifyOTPResponse(null, "");
            toggleAuthFormCb("login");
          } else {
            SetIsSubmitting(false);
            setMsg(
              res?.login_user?.message ||
                "Something went wrong. Please try again."
            );
          }
        }
      );
    }
  };

  const onResendOtpClick = () => {
    if (userName && counter === 0) {
      appSendLoginOtp({ login_id: userName }).then((res) => {
        res = JSON.parse(res.data);
        if (res?.status === "success") {
          setCounter(59);
          setResponseData(res);
        } else {
          setMsg(res?.message || "Something went wrong. Please try again.");
        }
      });
    }
  };

  return (
    <>
      <form onSubmit={onVerifyOTPLogin}>
        <div className="form-input contc-frm mt-25">
          <p>{responseData?.message}</p>
        </div>
        <div className="form-input contc-frm mt-25">
          <div className="input-group">
            <label className="lblchange">
              Enter OTP<sup>* </sup>
            </label>
            <input
              type="text"
              placeholder="Enter OTP"
              value={otpToVerify}
              onChange={(e) => setOtpToVerify(e.target.value)}
              style = {{outline:"none"}}
            />
          </div>
        </div>
        {msg.length > 0 ? <span className="text-danger">{msg}</span> : null}
        <button
          onClick={onVerifyOTPLogin}
          type="button"
          className="btn btn-primary mt-35"
          disabled={isSubmitting}
        >
          {isSubmitting ? PLEASE_WAIT_TXT : "LOGIN"}
        </button>
        <div className="row">
          <div className="col-md-6">
            {counter > 0 && <p className="mt-15">Expires in {counter}s</p>}
          </div>
          <div className="col-md-6">
            <button
              className="btn-blank mt-15"
              type="button"
              disabled={counter > 0}
              onClick={onResendOtpClick}
            >
              <p style={{color: counter? "#949494":"black"}}>Resend OTP</p>
            </button>
          </div>
        </div>
      </form>
    </>
  );
}

export default OTPLogin;
