import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { setOpenPoup } from "../../store/actions/popupActions";
import { isMobile, isIOS } from "react-device-detect";

function WidgetLinks() {
  const authState = useSelector((state) => state.auth);
  // const dispatch = useDispatch();
  const navigate = useNavigate();

  const onTrackYourOrderClick = () => {
    if (authState?.token && authState?.isAuthenticated && authState?.user) {
      return navigate("/accounts/order-history");
    } else {
      return navigate("/track-your-order");
    }
  };

  const dispatch = useDispatch();
  const openAuthModal = () => {
    dispatch(setOpenPoup("signup"));
  };

  const app_link = isIOS
    ? "https://apps.apple.com/in/app/darveys-luxury-shopping-india/id1460138461"
    : "https://play.google.com/store/apps/details?id=com.darveys.app";

  // const onAuthenticBtnClick = () => {
  //   dispatch(setOpenPoup("authentic"));
  // };
  return (
    <>
      <div className="col-md-3 col-6">
        <ul className="footer-widget-one">
          <li>
            <Link to="/about-us">About Darveys</Link>
          </li>
          <li>
            <Link to="/press-and-media/magazine">Press &amp; Events</Link>
          </li>
          <li>
            <Link to="/terms-and-conditions">Terms &amp; Conditions</Link>
          </li>
          <li>
            <Link to="/privacy-policy">Privacy Policy</Link>
          </li>
          <li>
            <Link to="/faq">FAQs</Link>
          </li>
          <li>
            <Link to="/return-and-exchange">Return &amp; Refund Policy</Link>
          </li>
          <li>
            <Link to="/shipping-information">Shipping Information</Link>
          </li>
        </ul>
        {isMobile && (
          <div className="foot-heading mt-40 ml-10 d-mbl">
            <h4>
              <a href={app_link} rel="noreferrer">
                <img
                  src="/assets/images/icons/smartphone-call.png"
                  className="mbl-icn-ftr"
                  alt=""
                />
                View Darveys App
              </a>
            </h4>
          </div>
        )}
      </div>
      <div className="col-md-3 col-6">
        <ul className="footer-widget-two">
          <li>
            <Link to="/contact-us">Contact Us</Link>
          </li>
          <li>
            <a href="" onClick={onTrackYourOrderClick}>
              Track Your Order
            </a>
          </li>
          <li>
            {/* 
            @BOC
            @Task #149943 Same Blog getting Live on Different URLS (with US sub directory)
            @author Almaaz Ahmed
            @date 03-June-2024
            @use_of_code:- I have added the following anchor tag to redirect the page to the external link provided below. This change ensures that only one blog post page exists for both the US and India. Previously, we had separate blog pages for India and the US, but now the US blog page is no longer needed.
            */}
            <a href="https://www.darveys.com/blog">Blog</a>
            {/* 
            EOC
            */}
          </li>
          {/* <li>
            <Link to="/gift-card">Gift Card</Link>
          </li> */}
          {authState?.token && authState?.isAuthenticated && authState?.user ? (
            ""
          ) : (
            <li>
              <button className="btn-blank" onClick={openAuthModal}>
                Newsletter
              </button>
            </li>
          )}
          <li>
            <Link to="/career">Careers</Link>
          </li>
          <li>
            {/* <span className="authentic cursor-pointer" onClick={onAuthenticBtnClick}>100% Authentic</span> */}
            {/* <Link to="/authentic">100% Authentic</Link> */}
          </li>
          <li>{/* <Link to="/testimonials">Testimonials</Link> */}</li>
        </ul>

        <div className="follow-us d-mbl">
          <div className="foot-heading">
            <h4>Follow us:</h4>
          </div>
          <ul className="social-media pl-0">
            <li>
              <a
                href="https://www.facebook.com/Darveys"
                target={"_blank"}
                rel="noreferrer"
              >
                <img
                  src={
                    process.env.PUBLIC_URL + "/assets/images/icons/Facebook.svg"
                  }
                  alt=""
                />
              </a>
            </li>
            <li>
              <a
                href="http://instagram.com/darveys"
                target={"_blank"}
                rel="noreferrer"
              >
                <img
                  src={
                    process.env.PUBLIC_URL +
                    "/assets/images/icons/Instagram.svg"
                  }
                  alt=""
                />
              </a>
            </li>
            <li>
              <a
                href="http://www.pinterest.com/darveys/"
                target={"_blank"}
                rel="noreferrer"
              >
                <img
                  src={
                    process.env.PUBLIC_URL +
                    "/assets/images/icons/Pinstrest.svg"
                  }
                  alt=""
                />
              </a>
            </li>
            <li>
              <a
                href="http://www.twitter.com/Darveyscom"
                target={"_blank"}
                rel="noreferrer"
              >
                <img
                  src={
                    process.env.PUBLIC_URL + "/assets/images/icons/Twitter.svg"
                  }
                  alt=""
                />
              </a>
            </li>
          </ul>
        </div>
      </div>
    </>
  );
}

export default WidgetLinks;
