// Sort Options Array
//BOC Task #137132 - Regarding Actual sizes updated the code and remove the () and add the - symbol in the sorting changes by Mohammad meraj
export const sortOrderOptions = [
  { title: "Relevance", id: "relevance" },
  { title: "New arrivals", id: "new" },
  { title: "Price - highest first", id: "high" },
  { title: "Price - lowest first", id: "low" },
  { title: "Discount - highest first", id: "dis_high_to_low" },
  { title: "Discount - lowest first", id: "dis_low_to_high" },
];

// Local Storage Key
// Token key
export const TOKEN_KEY = "token";
export const USER_KEY = "user";
export const LS_CAT_PRODS_KEY = "pr_cat_data";
export const LS_CART_COUNT = "cart_count";

// Recent Product Key
export const RECENT_VIEWED_PRODUCTS = "recent_viewed_products";
// The maximum number of recent products to be stored in local storage
export const RECENT_VIEWED_PRODUCTS_COUNT = "15";

// API RESPONSE TIME
export const API_RESPONSE_TIME_LOGGING_ENABLED = true;

// Size Opttion Group Id
export const SIZE_OPTION_GROUP_ID = "171";

// REGEX for validation
export const REGEX_MOBILE_NUMBER = /^\d{10}$/;
export const REGEX_EMAIL =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export const REGEX_PINCODE = /^\d{5}$/;
// at least 1 uppercase, 1 lowercase, 1 numeric, 1 special characters, 8-16 chars long
// export const REGEX_STRONG_PASSWORD =
//   "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\\$%\\^&\\*])(?=.{8,16})";
export const REGEX_STRONG_PASSWORD = /^.{6,}$/;  

  // REGEX for OTP - 6 digit
export const REGEX_OTP = /^\d{6}$/;

// Width For Mobile View
export const MOBIEL_VIEW_WIDTH = "991";

//Totals Key
export const SUB_TOTAL_KEY = "Sub total";
export const GRAND_TOTAL_KEY = "Grand total";

export const PLEASE_WAIT_TXT = "Please wait..."

// API Stale Time for cache
export const DEFAULT_API_STALE_TIME = 5 * 60 * 1000; // 5 mins
export const HOME_API_STALE_TIME = 30 * 60 * 1000; // 30 mins

// Product
export const PERC_OFF_TEXT = '% OFF';

//Error Message
export const ErrorsMsg = {
  required: "This is a required field.",
  validEmail: "Please enter a valid email.",
  validPhone: "Please enter a valid phone number.",
  // strongPwd: "Password must contain at least one uppercase, one lowercase, one numeric and one special character and length should be between 8-16 characters.",
  strongPwd: "Password must contain at least 6 characters",
  validPincode: "Please enter a valid pincode.",
  matchPwd: "Passwords must match.",
};

export const FACEBOOK_PIXEL_ID = "289894514879009";


export const MAX_IMAGE_SIZE = 5 * 1024 * 1024; 